#info-page {
  height: 100%; }
  #info-page .header-img {
    object-fit: contain;
    width: 100%;
    max-height: 1300px; }
  #info-page .info-content {
    padding: 15px;
    display: block;
    margin-bottom: 75px; }
  #info-page .rain-or-shine {
    font-size: 22px; }
  #info-page .event-pic {
    border: 1px solid lightgray; }
  #info-page .section-header {
    color: #535353;
    font-weight: 600;
    font-size: 26px;
    margin-top: 15px; }
  #info-page .support-section {
    display: flex;
    flex-direction: column;
    justify-content: center; }
  #info-page .sponser-logos-row {
    justify-content: center; }
    #info-page .sponser-logos-row .img-col {
      display: flex;
      justify-content: center;
      padding-top: 15px; }
    #info-page .sponser-logos-row img {
      max-height: 65px; }
  #info-page .faqs .question-row {
    display: flex;
    align-items: center;
    margin-top: 15px; }
    #info-page .faqs .question-row svg {
      width: auto;
      flex-shrink: 0;
      align-self: flex-start;
      margin-top: 12px;
      margin-right: 5px; }
    #info-page .faqs .question-row .faq-header-btn {
      font-weight: 500;
      font-size: 20px;
      color: #bccd50;
      text-align: left;
      padding-left: 3px;
      text-decoration: none !important;
      line-height: 1.2; }
  #info-page .back-to-map {
    background-color: #bccd50;
    height: 75px;
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 600;
    font-size: 24px; }
    #info-page .back-to-map p {
      margin: 0; }
    @media (min-width: 768px) {
      #info-page .back-to-map {
        display: none; } }
