.index-page .map-container {
  display: flex;
  left: 0;
  position: fixed;
  transform: translate3d(0, 0, 0);
  padding-top: env(safe-area-inset-top);
  right: 0;
  top: auto;
  height: 50vh; }
  @media (min-width: 768px) {
    .index-page .map-container {
      height: 100vh; } }

.index-page .top-buffer {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin: 0;
  outline: none;
  width: 100%;
  z-index: -1;
  height: 50vh; }
  @media (min-width: 768px) {
    .index-page .top-buffer {
      height: 3vh; } }

.index-page .focus-btns {
  position: fixed; }
  @media (min-width: 768px) {
    .index-page .focus-btns {
      bottom: 0; } }
  .index-page .focus-btns .focus-location-btn {
    margin-bottom: 15px;
    margin-right: 15px;
    background-color: white;
    border-radius: 50%;
    color: #535353;
    height: 55px;
    width: 55px;
    display: flex;
    justify-content: center;
    align-items: center; }
  .index-page .focus-btns .focus-center-btn {
    margin-bottom: 20px;
    margin-right: 15px;
    background-color: white;
    border-radius: 50%;
    color: #535353;
    height: 55px;
    width: 55px;
    display: flex;
    justify-content: center;
    align-items: center; }

.add-to-home-banner {
  padding: 10px;
  background-color: #bccd50 !important;
  color: white !important;
  box-shadow: 0 -0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }
  .add-to-home-banner .add-to-home-content {
    width: 100%; }
  .add-to-home-banner .add-to-home-icon {
    padding: 0 !important; }
  .add-to-home-banner .add-to-home-text {
    width: 100%; }
    .add-to-home-banner .add-to-home-text a {
      width: 100%;
      display: block; }
