body {
  font-family: 'Muli', sans-serif; }

.header-navbar {
  justify-content: flex-start;
  height: 70px;
  z-index: 1051; }
  .header-navbar .nav-logo {
    max-height: 45px; }
  .header-navbar .info-link {
    color: #bccd50;
    cursor: pointer; }
    .header-navbar .info-link svg {
      margin-right: 8px; }
    .header-navbar .info-link p {
      display: inline;
      margin: 0;
      font-size: 20px;
      font-weight: 600; }

.page-content {
  margin-top: 70px; }

.modal {
  -webkit-overflow-scrolling: touch; }

.info-modal {
  margin: 70px 0 0 0;
  background-color: white;
  max-width: 100%;
  transition: unset !important; }
