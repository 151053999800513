.activity-hub-card {
  padding: 15px 15px 15px 20px;
  background-color: white;
  text-align: left;
  color: #535353;
  border-color: #f3f3f3;
  border-radius: 10px;
  margin-top: 15px; }
  .activity-hub-card:hover, .activity-hub-card:active {
    background-color: white !important;
    border-color: #f3f3f3 !important;
    color: #535353 !important; }
  .activity-hub-card .ah-title-and-logo {
    display: flex;
    flex-direction: row; }
    .activity-hub-card .ah-title-and-logo p.ah-title {
      font-size: 22px;
      font-weight: 400;
      line-height: 1.1;
      padding-top: 5px;
      margin-bottom: 10px;
      width: 70%; }
      @media (min-width: 768px) {
        .activity-hub-card .ah-title-and-logo p.ah-title {
          font-size: 16px;
          font-weight: 700; } }
    .activity-hub-card .ah-title-and-logo .ah-logo {
      margin-top: -10px;
      background-position: right;
      background-repeat: no-repeat;
      background-size: 80px;
      width: 30%; }
  .activity-hub-card p.ah-subtitle {
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 5px; }
    @media (min-width: 768px) {
      .activity-hub-card p.ah-subtitle {
        font-size: 12px; } }
