.overlay-panel {
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955), transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  -moz-transition: transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transition: -ms-transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955), -webkit-transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955), transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  will-change: transform;
  width: 100%;
  padding: 15px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  box-shadow: 0 -0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  border: 1px solid lightgray;
  background-color: white; }
  @media (min-width: 768px) {
    .overlay-panel {
      width: 400px;
      margin-left: 20px;
      border-radius: 10px;
      height: 60vh;
      overflow-y: scroll; } }
  @media (min-width: 992px) {
    .overlay-panel {
      width: 400px; } }
  .overlay-panel .category-btns {
    margin-top: 10px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    flex-wrap: nowrap;
    flex-shrink: 0;
    overflow-x: scroll;
    cursor: pointer; }
    .overlay-panel .category-btns .category-btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 0 0 auto;
      margin-left: 5px;
      margin-right: 5px;
      background-color: white;
      color: #535353;
      border: 1px solid #f3f3f3;
      border-radius: 10px;
      width: 24vw;
      font-size: 12px;
      padding: 15px 0 5px 0;
      text-align: center;
      box-shadow: 0 0.2rem 0.45rem rgba(0, 0, 0, 0.1) !important; }
      @media (min-width: 768px) {
        .overlay-panel .category-btns .category-btn {
          width: unset;
          padding: 15px 10px 5px 10px; } }
      .overlay-panel .category-btns .category-btn svg {
        margin-bottom: 5px; }
      .overlay-panel .category-btns .category-btn p {
        margin-bottom: 5px; }
      .overlay-panel .category-btns .category-btn.active {
        background-color: #535353 !important;
        border-color: #f3f3f3;
        color: white !important; }
        .overlay-panel .category-btns .category-btn.active svg {
          color: white; }
  .overlay-panel .tab-section-header {
    padding: 15px 15px 0 15px;
    margin-bottom: 0; }
