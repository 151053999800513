.pwa-modal {
  justify-content: center; }
  .pwa-modal .modal-content {
    width: 80%; }
  .pwa-modal .pwa-modal-inner-content {
    display: flex;
    flex-direction: column;
    padding: 24px 24px;
    color: #535353; }
    .pwa-modal .pwa-modal-inner-content h2 {
      margin-bottom: 25px;
      font-size: 26px;
      font-weight: 700;
      text-align: center; }
    .pwa-modal .pwa-modal-inner-content .text-and-share {
      line-height: 25px;
      margin-top: 10px; }
      .pwa-modal .pwa-modal-inner-content .text-and-share .share-img {
        width: 24px;
        height: auto;
        margin-left: 5px;
        margin-right: 5px;
        margin-top: -15px; }
    .pwa-modal .pwa-modal-inner-content .done-btn {
      margin-top: 25px;
      margin-bottom: 5px;
      background-color: #bccd50;
      border-radius: 40px;
      border-color: white; }
