.placemark-card {
  padding: 15px 20px;
  background-color: white;
  text-align: left;
  color: #535353;
  border-color: #f3f3f3;
  border-radius: 10px;
  margin-top: 15px; }
  .placemark-card:hover, .placemark-card:active {
    background-color: white !important;
    border-color: #f3f3f3 !important;
    color: #535353 !important; }
  .placemark-card .info-col {
    padding-right: 0; }
    .placemark-card .info-col p.card-title {
      font-size: 22px;
      font-weight: 400;
      line-height: 1.1;
      padding-top: 5px;
      margin-bottom: 10px; }
      @media (min-width: 768px) {
        .placemark-card .info-col p.card-title {
          font-size: 16px;
          font-weight: 700; } }
    .placemark-card .info-col p.card-subtitle {
      font-size: 14px;
      font-weight: 300;
      margin-bottom: 5px; }
      @media (min-width: 768px) {
        .placemark-card .info-col p.card-subtitle {
          font-size: 12px; } }
  .placemark-card .map-col {
    align-self: center; }
    .placemark-card .map-col .map-link {
      color: #bccd50; }
